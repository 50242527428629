import { Injectable } from "@angular/core";
import { environment } from "../../../environments/environment";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import {
  CategorieMenuRes,
  TrendingServiceRes,
  BusinessRes,
  BusinessListDetailsRes,
  BusinessTemplateDetailsRes,
  ProductDetailRes,
  ServiceDetailRes,
  InvestmentOpportunityRes,
  BusinessRatingRes,
  PostJsonBusinessList,
  MarketPlaceListRes,
  PostJsonMarketPlaceList,
  MarketPlaceDetailRes,
} from "src/app/shared/class/data.model";
  
import { Observable, map } from 'rxjs';
import { CommonService } from "./common.service";
// import { environment } from '../../../environments/environment';
// import { CategorieMenuRes } from './../../class/data.model';

@Injectable()
export class HomeService {
  public menuCategories: CategorieMenuRes;
  public trendingServicesList: TrendingServiceRes;
  public trendingCategoryList: TrendingServiceRes;
  public promotedBusinessList: BusinessRes;
  public investmentOpportunityList: InvestmentOpportunityRes;
  public recentlyAddedBusinessList: BusinessRes;
  public popularBusinessList: BusinessRes;
  public premiumBusinessList: BusinessRes;

  HttpHeaderOptions = {
    headers: new HttpHeaders({
      "Content-Type": "application/json",
    }),
  };

  constructor(private hC: HttpClient, private cS: CommonService) {
    this.menuCategories = {
      status: 0,
      message: "",
      data: [],
    };

    this.trendingServicesList = {
      status: 0,
      message: "",
      data: [],
    };

    this.trendingCategoryList = {
      status: 0,
      message: "",
      data: [],
    };

    this.promotedBusinessList = {
      status: 0,
      message: "",
      data: [],
    };

    this.premiumBusinessList = {
      status: 0,
      message: "",
      data: [],
    };

    this.investmentOpportunityList = {
      status: 0,
      message: "",
      loadMore: 0,
      data: [],
    };

    this.recentlyAddedBusinessList = {
      status: 0,
      message: "",
      data: [],
    };

    this.popularBusinessList = {
      status: 0,
      message: "",
      data: [],
    };
  }

  private getBaseUrl() {
    return (
      location.protocol +
      "//" +
      location.hostname +
      (location.port ? ":" + location.port : "") +
      "/"
    );
  }

  public getPrivacyPolicyRYC(): Observable<any> {
    return this.hC.get(
      this.getBaseUrl() + "assets/json/ryc-privacy-policy.json"
    );
  }

  public getPrivacyPolicyMRC(): Observable<any> {
    return this.hC.get(
      this.getBaseUrl() + "assets/json/mrc-privacy-policy.json"
    );
  }
  /**
   * Get Trending service listing
   * @returns Observable<TrendingServiceRes>
   */
  getTrendingServiceListing(limit?: number): Observable<TrendingServiceRes> {
    let url = `${environment.RYEC_API_URL}getTrendingServices`;
    if (limit) {
      url = `${environment.RYEC_API_URL}getTrendingServices?limit=${limit}`;
    }
    return this.hC
      .get<TrendingServiceRes>(url)
      .pipe(map((res: TrendingServiceRes) => {
        this.trendingServicesList = res;
        return res;
      }));
  }

  /**
   * Get Trending Category listing
   * @returns Observable<TrendingServiceRes>
   */
  getTrendingCategorieListing(limit?: number): Observable<TrendingServiceRes> {
    let url = `${environment.RYEC_API_URL}getTrendingCategories`;
    if (limit) {
      url = `${environment.RYEC_API_URL}getTrendingCategories?limit=${limit}`;
    }
    return this.hC.get<TrendingServiceRes>(url).pipe(
      map((res: TrendingServiceRes) => {
        this.trendingCategoryList = res;
        return res;
      })
    );;
  }

  /**
   * Get Promoted Business listing
   * @returns Observable<BusinessRes>
   */
  getPromotedBusinessListing(
    postJson?: any
  ): Observable<BusinessRes> {
    let body = "";

    if (postJson) {
      for (const key in postJson) {
        if (postJson.hasOwnProperty(key)) {
          body += key + "=" + postJson[key] + "&";
        }
      }
    }
    const url = environment.RYEC_API_URL + "getPromotedBusinesses?" + body;

    return this.hC.get<BusinessRes>(url).pipe(map((res: BusinessRes) => {
      if (postJson) {
        if ("limit" in postJson) {
          this.promotedBusinessList = res;debugger
        }
      }
      return res;
    }));
  }

  /**
   * Get Investment Opportunity listing
   * @returns Observable<InvestmentOpportunityRes>
   */
  getInvestmentOpportunityList(
    postJson?: any
  ): Observable<InvestmentOpportunityRes> {
    const url = environment.RYEC_API_URL + "getInvestmentIdeas";

    return this.hC
      .post<InvestmentOpportunityRes>(url, postJson, this.HttpHeaderOptions)
      .pipe(map((res: InvestmentOpportunityRes) => {
        if (postJson) {
          if ("limit" in postJson) {
            this.investmentOpportunityList = res;
          }
        }
        return res;
      }));
  }

  /**
   * Get Investment Opportunity listing
   * @returns Observable<InvestmentOpportunityRes>
   */
  getMyInvestmentInterestList(): Observable<any> {
    const url = environment.RYEC_API_URL + "getMyInvestmentInterest";

    return this.hC.post<any>(url, this.HttpHeaderOptions).pipe(map((res: any) => {
      return res;
    }));
  }

  /**
   * Get Investment Opportunity Filters
   * @returns Observable<>
   */
  getInvestmentFilters(): Observable<any> {
    const url = environment.RYEC_API_URL + "getInvestmentFilters";

    return this.hC.get<any>(url).pipe(map((res: any) => {
      return res;
    }));
  }

  /**
   * Get Business Detail by business slug
   * @returns BusinessListDetailsRes
   */
  getInvestmentDetailBySlug(postJson?: {}): Observable<InvestmentOpportunityRes> {
    const url = environment.RYEC_API_URL + "getInvestmentIdeaDetails";

    return this.hC
      .post<InvestmentOpportunityRes>(url, postJson, this.HttpHeaderOptions)
      .pipe(map((res: InvestmentOpportunityRes) => {
        return res;
      }));
  }

  /**
   * Get Recently added Business listing
   * @returns Observable<BusinessRes>
   */
  getRecentlyAddedBusinessListing(
    postJson?: PostJsonBusinessList
  ): Observable<BusinessRes> {
    const url = environment.RYEC_API_URL + "getRecentlyAddedBusinessListing";

    return this.hC
      .post<BusinessRes>(url, postJson, this.HttpHeaderOptions)
      .pipe(map((res: BusinessRes) => {
        if (postJson) {
          if ("limit" in postJson) {
            this.recentlyAddedBusinessList = res;
          }
        }
        return res;
      }));
  }

  /**
   * Get Popular Business listing
   * @returns Observable<BusinessRes>
   */
  getPopularBusinessList(
    postJson?: any
  ): Observable<BusinessRes> {
    let body = "";
    if (postJson) {
      for (const key in postJson) {
        if (postJson.hasOwnProperty(key)) {
          body += key + "=" + postJson[key] + "&";
        }
      }
    }

    const url = environment.RYEC_API_URL + "getPopularBusinesses?" + body;
    return this.hC.get<BusinessRes>(url).pipe(map((res: BusinessRes) => {
      if (postJson) {
        if ("limit" in postJson) {
          this.popularBusinessList = res;
        }
      }
      return res;
    }));
  }

  /**
   * Get Category wise Business listing
   * @returns void
   */
  getBusinessListByCategory(postJson?: PostJsonBusinessList) {
    const url = environment.RYEC_API_URL + "getBusinessListing";

    return this.hC
      .post(url, postJson, this.HttpHeaderOptions)
      .pipe(map((res: any) => {
        return res;
      }));
  }

  getJobsList(postJson?: PostJsonBusinessList): Observable<BusinessRes> {
    const url = environment.RYEC_API_URL + "jobList";
    return this.hC
      .post<BusinessRes>(url, postJson, this.HttpHeaderOptions)
      .pipe(map((res: BusinessRes) => {
        if (postJson) {
          if ("limit" in postJson) {
            this.recentlyAddedBusinessList = res;
          }
        }
        return res;
      }));
  }
  getEventsList(postJson?: PostJsonBusinessList): Observable<BusinessRes> {
    const url = environment.RYEC_API_URL + "eventList";
    return this.hC
      .post<BusinessRes>(url, postJson, this.HttpHeaderOptions)
      .pipe(map((res: BusinessRes) => {
        if (postJson) {
          if ("limit" in postJson) {
            this.recentlyAddedBusinessList = res;
          }
        }
        return res;
      }));
  }

  getGroupsList(postJson?: PostJsonBusinessList): Observable<BusinessRes> {
    const url = environment.RYEC_API_URL + "sites";

    return this.hC
      .post<BusinessRes>(url, postJson, this.HttpHeaderOptions)
      .pipe(map((res: BusinessRes) => {
        if (postJson) {
          if ("limit" in postJson) {
            this.recentlyAddedBusinessList = res;
          }
        }
        return res;
      }));
  }
  getNewsList(postJson?: PostJsonBusinessList): Observable<BusinessRes> {
    const url = environment.RYEC_API_URL + "newsList";

    return this.hC
      .post<BusinessRes>(url, postJson, this.HttpHeaderOptions)
      .pipe(map((res: BusinessRes) => {
        if (postJson) {
          if ("limit" in postJson) {
            this.recentlyAddedBusinessList = res;
          }
        }
        return res;
      }));
  }
  getmsgList(postJson?: PostJsonBusinessList): Observable<BusinessRes> {
    const url = environment.RYEC_API_URL + "magazineList";

    return this.hC
      .post<BusinessRes>(url, postJson, this.HttpHeaderOptions)
      .pipe(map((res: BusinessRes) => {
        if (postJson) {
          if ("limit" in postJson) {
            this.recentlyAddedBusinessList = res;
          }
        }
        return res;
      }));
  }

  getNewsDetails(postJson?: any): Observable<BusinessRes> {
    const url = environment.RYEC_API_URL + "newsDetails";

    return this.hC
      .post<BusinessRes>(url, postJson, this.HttpHeaderOptions)
      .pipe(map((res: BusinessRes) => {
        if (postJson) {
          if ("limit" in postJson) {
            this.recentlyAddedBusinessList = res;
          }
        }
        return res;
      }));
  }
  getSubCategory(postJson?: PostJsonBusinessList): Observable<BusinessRes> {
    const url = environment.RYEC_API_URL + "getSubCategory";

    return this.hC
      .post<BusinessRes>(url, postJson, this.HttpHeaderOptions)
      .pipe(map((res: BusinessRes) => {
        if (postJson) {
          if ("limit" in postJson) {
            this.recentlyAddedBusinessList = res;
          }
        }
        return res;
      }));
  }
  getApplicantUsersListByJobVacancyId(
    postJson?: PostJsonBusinessList
  ): Observable<BusinessRes> {
    const url = environment.RYEC_API_URL + "getJobAppliedUsersByJobVacancyId";
    return this.hC
      .post<BusinessRes>(url, postJson, this.HttpHeaderOptions)
      .pipe(map((res: BusinessRes) => {
        if (postJson) {
          if ("limit" in postJson) {
            this.recentlyAddedBusinessList = res;
          }
        }
        return res;
      }));
  }
  getMyGroupsList(postJson?: PostJsonBusinessList): Observable<BusinessRes> {
    const url = environment.RYEC_API_URL + "myGroupLists";

    return this.hC
      .post<BusinessRes>(url, postJson, this.HttpHeaderOptions)
      .pipe(map((res: BusinessRes) => {
        if (postJson) {
          if ("limit" in postJson) {
            this.recentlyAddedBusinessList = res;
          }
        }
        return res;
      }));
  }
  getPostLikedUsersList(
    postJson?: PostJsonBusinessList
  ): Observable<BusinessRes> {
    const url = environment.RYEC_API_URL + "getLikedUsers";
    return this.hC
      .post<BusinessRes>(url, postJson, this.HttpHeaderOptions)
      .pipe(map((res: BusinessRes) => {
        if (postJson) {
          if ("limit" in postJson) {
            this.recentlyAddedBusinessList = res;
          }
        }
        return res;
      }));
  }

  getEventAttendeesListByEventId(
    postJson?: PostJsonBusinessList
  ): Observable<BusinessRes> {
    const url = environment.RYEC_API_URL + "eventAttendees";
    return this.hC
      .post<BusinessRes>(url, postJson, this.HttpHeaderOptions)
      .pipe(map((res: BusinessRes) => {
        if (postJson) {
          if ("limit" in postJson) {
            this.recentlyAddedBusinessList = res;
          }
        }
        return res;
      }));
  }

  /**
   * Get Business Detail by business slug
   * @returns BusinessListDetailsRes
   */
  getBusinessDetailBySlug(postJson?: {}): Observable<BusinessListDetailsRes> {
    const url = environment.RYEC_API_URL + "getBusinessDetail";

    return this.hC
      .post<BusinessListDetailsRes>(url, postJson, this.HttpHeaderOptions)
      .pipe(map((res: BusinessListDetailsRes) => {
        return res;
      }));
  }

  generateQrCodeByBusinessId(postJson?: {}): Observable<any> {
    const url = environment.RYEC_API_URL + "generateQrCode";

    return this.hC
      .post<any>(url, postJson, this.HttpHeaderOptions)
      .pipe(map((res: any) => {
        return res;
      }));
  }

  getEntityKnowMore(postJson?: {}): Observable<any> {
    const url = environment.RYEC_API_URL + "getEntityKnowMore";

    return this.hC
      .post<any>(url, postJson, this.HttpHeaderOptions)
      .pipe(map((res: any) => {
        return res;
      }));
  }

  getSubAssetTypeFields(postJson?: {}): Observable<any> {
    const url = environment.RYEC_API_URL + "getSubAssetTypeFields";

    return this.hC
      .post<any>(url, postJson, this.HttpHeaderOptions)
      .pipe(map((res: any) => {
        return res;
      }));
  }

  getEntityInOtherLang(postJson?: {}): Observable<any> {
    const url = environment.RYEC_API_URL + "getEntityInOtherLang";

    return this.hC
      .post<any>(url, postJson, this.HttpHeaderOptions)
      .pipe(map((res: any) => {
        return res;
      }));
  }
  getEntityCustomDetails(postJson?: {}): Observable<any> {
    const url = environment.RYEC_API_URL + "getEntityCustomDetails";

    return this.hC
      .post<any>(url, postJson, this.HttpHeaderOptions)
      .pipe(map((res: any) => {
        return res;
      }));
  }
  getFilterEntitiesForNearBy(postJson?: {}): Observable<any> {
    const url = environment.RYEC_API_URL + "getFilterEntitiesForNearBy";

    return this.hC
      .post<any>(url, postJson, this.HttpHeaderOptions)
      .pipe(map((res: any) => {
        return res;
      }));
  }
  getEntityNearBy(postJson?: {}): Observable<any> {
    const url = environment.RYEC_API_URL + "getEntityNearBy";

    return this.hC
      .post<any>(url, postJson, this.HttpHeaderOptions)
      .pipe(map((res: any) => {
        return res;
      }));
  }

  getVideoLists(postJson?: {}): Observable<any> {
    const url = environment.RYEC_API_URL + "getEntityVideo";

    return this.hC
      .post<any>(url, postJson, this.HttpHeaderOptions)
      .pipe(map((res: any) => {
        return res;
      }));
  }

  getVideoById(postJson?: {}): Observable<any> {
    const url = environment.RYEC_API_URL + "getVideoById";

    return this.hC
      .post<any>(url, postJson, this.HttpHeaderOptions)
      .pipe(map((res: any) => {
        return res;
      }));
  }

  getNewsDetailsBySlug(postJson?: {}): Observable<any> {
    const url = environment.RYEC_API_URL + "getNewsDetailsBySlug";
    return this.hC.post<any>(url, postJson, this.HttpHeaderOptions).pipe(map((res: any) => {
      return res;
    }));
  }

  getNewsCommentsListByNewsId(postJson?: {}): Observable<any> {
    const url = environment.RYEC_API_URL + "newsCommentLists";
    return this.hC.post<any>(url, postJson, this.HttpHeaderOptions).pipe(map((res: any) => {
      return res;
    }));
  }

  getGroupDetailsById(postJson?: {}): Observable<any> {

    
    const url = environment.RYEC_API_URL + "getSite";

    return this.hC
      .post<any>(url, postJson, this.HttpHeaderOptions)
      .pipe(map((res: any) => {
        return res;
      }));
  }

  getTemplateDetailsById(postJson?: {}): Observable<any> {
    const url = environment.RYEC_API_URL + "getBusinessDoc";

    return this.hC
      .post<any>(url, postJson, this.HttpHeaderOptions)
      .pipe(map((res: any) => {
        return res;
      }));
  }

  getJobDetailsById(postJson?: {}): Observable<any> {
    const url = environment.RYEC_API_URL + "JobDetails";

    return this.hC
      .post<any>(url, postJson, this.HttpHeaderOptions)
      .pipe(map((res: any) => {
        return res;
      }));
  }

  getEventDetailsById(postJson?: {}): Observable<any> {
    const url = environment.RYEC_API_URL + "eventDetails";

    return this.hC
      .post<any>(url, postJson, this.HttpHeaderOptions)
      .pipe(map((res: any) => {
        return res;
      }));
  }

  getGroupMemberById(postJson?: {}): Observable<any> {
    const url = environment.RYEC_API_URL + "siteMembers";

    return this.hC
      .post<any>(url, postJson, this.HttpHeaderOptions)
      .pipe(map((res: any) => {
        return res;
      }));
  }

  getGroupMemberICardById(postJson?: {}): Observable<any> {
    const url = environment.RYEC_API_URL + "getGroupUserIdCard";

    return this.hC
      .post<any>(url, postJson, this.HttpHeaderOptions)
      .pipe(map((res: any) => {
        return res;
      }));
  }

  getGroupJoinRequestMembersListById(postJson?: {}): Observable<any> {
    const url = environment.RYEC_API_URL + "pendingJoinMembers";
    return this.hC
      .post<any>(url, postJson, this.HttpHeaderOptions)
      .pipe(map((res: any) => {
        return res;
      }));
  }

  getPublicBusinessDetailBySlug(postJson?: any): Observable<BusinessListDetailsRes> {
    let url = environment.RYEC_API_URL + "getPublicBusinessDetail";
    if (postJson) {
      url =
        environment.RYEC_API_URL +
        "getPublicBusinessDetail/" +
        postJson["business_slug"];
    }

    return this.hC
      .get<BusinessListDetailsRes>(url)
      .pipe(map((res: BusinessListDetailsRes) => {
        return res;
      }));
  }

  getPublicTemplateDetailBySlug(postJson?: any): Observable<BusinessTemplateDetailsRes> {
    let url = environment.RYEC_API_URL + "getPublicWebsiteTemplate";
    if (postJson) {
      url =
        environment.RYEC_API_URL +
        "getPublicWebsiteTemplate/" +
        postJson["business_slug"];
    }

    return this.hC
      .get<BusinessTemplateDetailsRes>(url)
      .pipe(map((res: BusinessTemplateDetailsRes) => { 
        return res;
      }));
  }

  /**
   * Get Product Detail by product id
   * @returns ProductDetailRes
   */
  getProductDetailByProductId(postJson?: {}): Observable<ProductDetailRes> {
    const url = environment.RYEC_API_URL + "getProductDetails";

    return this.hC
      .post<ProductDetailRes>(url, postJson, this.HttpHeaderOptions)
      .pipe(map((res: ProductDetailRes) => {
        return res;
      }));
  }

  getPublicProductDetailByProductId(postJson?: {}): Observable<ProductDetailRes> {
    const url = environment.RYEC_API_URL + "getPublicProductDetails";

    return this.hC
      .post<ProductDetailRes>(url, postJson, this.HttpHeaderOptions)
      .pipe(map((res: ProductDetailRes) => {
        return res;
      }));
  }

  /**
   * Get Service Detail by product id
   * @returns ServiceDetailRes
   */
  getServiceDetailByServiceId(postJson?: {}): Observable<ServiceDetailRes> {
    const url = environment.RYEC_API_URL + "getServiceDetails";

    return this.hC
      .post<ServiceDetailRes>(url, postJson, this.HttpHeaderOptions)
      .pipe(map((res: ServiceDetailRes) => {
        return res;
      }));
  }

  getPublicServiceDetailByServiceId(postJson?: {}): Observable<ServiceDetailRes> {
    const url = environment.RYEC_API_URL + "getPublicServiceDetails";

    return this.hC
      .post<ServiceDetailRes>(url, postJson, this.HttpHeaderOptions)
      .pipe(map((res: ServiceDetailRes) => {
        return res;
      }));
  }

  /**
   * Get Business Rating List by business id
   * @returns BusinessRatingRes
   */
  getBusinessRating(postJson?: {}): Observable<BusinessRatingRes> {
    const url = environment.RYEC_API_URL + "getBusinessRatings";

    return this.hC
      .post<BusinessRatingRes>(url, postJson, this.HttpHeaderOptions)
      .pipe(map((res: BusinessRatingRes) => {
        return res;
      }));
  }

  getPublicBusinessRating(postJson?: {}): Observable<BusinessRatingRes> {
    const url = environment.RYEC_API_URL + "getPublicBusinessRatings";

    return this.hC
      .post<BusinessRatingRes>(url, postJson, this.HttpHeaderOptions)
      .pipe(map((res: BusinessRatingRes) => {
        return res;
      }));
  }

  getPublicWebsiteReviews(postJson?: {}): Observable<BusinessRatingRes> {
    const url = environment.RYEC_API_URL + "getPublicWebsiteReviews";

    return this.hC
      .post<BusinessRatingRes>(url, postJson, this.HttpHeaderOptions)
      .pipe(map((res: BusinessRatingRes) => {
        return res;
      }));
  }

  /**
   * Get Near By Business List by
   * @returns BusinessRatingRes
   */
  getNearByBusinessesList(postJson?: {}): Observable<BusinessRes> {
    const url = environment.RYEC_API_URL + "getNearByBusinesses";

    return this.hC
      .post<BusinessRes>(url, postJson, this.HttpHeaderOptions)
      .pipe(map((res: BusinessRes) => {
        return res;
      }));
  }

  getMenuCategoryListing(): Observable<CategorieMenuRes> {
    const url = environment.RYEC_API_URL + "getMainCategory";
    return this.hC.get<CategorieMenuRes>(url).pipe(map((res) => {
      this.menuCategories = res;
      return res;
    }));
  }

  /**
   * Get Business List for agent
   * @returns BusinessRatingRes
   */
  getBusinessListForAgent(postJson?: {}): Observable<BusinessRes> {
    const url = environment.RYEC_API_URL + "getAgentBusinesses";

    return this.hC
      .post<BusinessRes>(url, postJson, this.HttpHeaderOptions)
      .pipe(map((res: BusinessRes) => {
        return res;
      }));
  }

  getAddressMaster(): Observable<any> {
    const url = environment.RYEC_API_URL + "getAddressMaster";
    return this.hC.get<any>(url).pipe(map((res) => {
      return res;
    }));
  }
  getBrandingData(): Observable<any> {
    const url = environment.RYEC_API_URL + "getBrandingData";
    return this.hC.get<any>(url).pipe(map((res) => {
      return res;
    }));
  }
  getOnlineStore(): Observable<any> {
    const url = environment.RYEC_API_URL + "getOnlineStores";
    return this.hC.post<any>(url, this.HttpHeaderOptions).pipe(map((res: any) => {
      return res;
    }));
  }
  
  getnewsLists(postJson?: {}): Observable<any> {
    
    const url = environment.RYEC_API_URL + "newsList";
    return this.hC.post<any>(url, postJson, this.HttpHeaderOptions).pipe(map((res: any) => {
        return res;
      }));
  }

  highlightservice(postJson?: {}): Observable<any> {
  
    const url = environment.RYEC_API_URL + "instructions";
    return this.hC.post<any>(url, postJson, this.HttpHeaderOptions).pipe(map((res: any) => {
        return res;
      }));
  }

  getBusinessOnlineStores(postJson?: {}): Observable<any> {
    const url = environment.RYEC_API_URL + "getBusinessOnlineStores";

    return this.hC
      .post<any>(url, postJson, this.HttpHeaderOptions)
      .pipe(map((res: any) => {
        return res;
      }));
  }

  getCategoriesLists(): Observable<any> {
    const url = environment.RYEC_API_URL + "getTrendingCategories";
    return this.hC.get<any>(url).pipe(map((res) => {
      return res;
    }));
  }

  getCountryCode(): Observable<any> {
    const url = environment.RYEC_API_URL + "getCountryCode";
    return this.hC.get<any>(url).pipe(map((res) => {
      return res;
    }));
  }

  getBusinessAndTopPlacesLists(postJson?: {}): Observable<any> {
    const url = environment.RYEC_API_URL + "getSponsoredEntity";

    return this.hC
      .post<any>(url, postJson, this.HttpHeaderOptions)
      .pipe(map((res: any) => {
        return res;
      }));
  }

  /**
   * Get search List for business
   * @returns BusinessRatingRes
   */
  getSearchAutocomplete(postJson?: {}): Observable<any> {
    const url = environment.RYEC_API_URL + "getSearchAutocomplete";

    return this.hC
      .post<any>(url, postJson, this.HttpHeaderOptions)
      .pipe(map((res: any) => {
        return res;
      }));
  }

  getGroupsAutoCompleteList(postJson?: {}): Observable<any> {
    const url = environment.RYEC_API_URL + "siteAutoSuggestion";
    return this.hC
      .post<any>(url, postJson, this.HttpHeaderOptions)
      .pipe(map((res: any) => {
        return res;
      }));
  }
  jobApplicantsAutoSuggestion(postJson?: {}): Observable<any> {
    const url = environment.RYEC_API_URL + "jobApplicantsAutoSuggestion";
    return this.hC
      .post<any>(url, postJson, this.HttpHeaderOptions)
      .pipe(map((res: any) => {
        return res;
      }));
  }
  getJobsAutoCompleteList(postJson?: {}): Observable<any> {
    const url = environment.RYEC_API_URL + "jobAutoSuggestion";
    return this.hC
      .post<any>(url, postJson, this.HttpHeaderOptions)
      .pipe(map((res: any) => {
        return res;
      }));
  }

  getGroupMemberAutocomplete(postJson?: {}): Observable<any> {
    const url = environment.RYEC_API_URL + "suggestSiteMembers";

    return this.hC
      .post<any>(url, postJson, this.HttpHeaderOptions)
      .pipe(map((res: any) => {
        return res;
      }));
  }

  getLocationByPinCode(postJson?: {}): Observable<any> {
    const url = environment.RYEC_API_URL + "getLocationByPincode";

    return this.hC
      .post<any>(url, postJson, this.HttpHeaderOptions)
      .pipe(map((res: any) => {
        return res;
      }));
  }
  getGroupMemberRoleLists(postJson?: {}): Observable<any> {
    const url = environment.RYEC_API_URL + "siteRoles";

    return this.hC
      .post<any>(url, postJson, this.HttpHeaderOptions)
      .pipe(map((res: any) => {
        return res;
      }));
  }
  getGroupPrivacysList(postJson?: {}): Observable<any> {
    const url = environment.RYEC_API_URL + "groupPrivacyList";

    return this.hC
      .post<any>(url, postJson, this.HttpHeaderOptions)
      .pipe(map((res: any) => {
        return res;
      }));
  }
  getGroupDesignationsListByGroupId(postJson?: {}): Observable<any> {
    const url = environment.RYEC_API_URL + "designationList";
    return this.hC
      .post<any>(url, postJson, this.HttpHeaderOptions)
      .pipe(map((res: any) => {
        return res;
      }));
  }
  getCityLists(postJson?: {}): Observable<any> {
    const url = environment.RYEC_API_URL + "cityList";

    return this.hC
      .post<any>(url, postJson, this.HttpHeaderOptions)
      .pipe(map((res: any) => {
        return res;
      }));
  }

  getSearchLocationList(postJson?: {}): Observable<any> {
    // const url = environment.RYEC_API_URL + "getAddressMaster";
    const url = `${environment.RYEC_API_URL}getAddressMaster?searchText=${postJson}`;
    return this.hC.get<any>(url, postJson).pipe(map((res) => {
      return res;
    }));

    // return this.hC.post<any>(url, postJson, this.HttpHeaderOptions).pipe(map((res: any) => {
    //   return res;
    // }));
  }

  /**
   * get report business reason list by id
   */
  getReasonsByEntityTypeByAssetsId(postJson?: {}): Observable<any> {
    const url = environment.RYEC_API_URL + "getReasonsByEntityType";

    return this.hC
      .post<any>(url, postJson, this.HttpHeaderOptions)
      .pipe(map((res: any) => {
        return res;
      }));
  }

  /**
   * get templates list by groupId id
   */
  getGroupTemplatesList(postJson?: {}): Observable<any> {
    const url = environment.RYEC_API_URL + "templateList";

    return this.hC
      .post<any>(url, postJson, this.HttpHeaderOptions)
      .pipe(map((res: any) => {
        return res;
      }));
  }
  /**
   * get report business reason list by id
   */
  getGroupTemplateDetailsByTemplateId(postJson?: {}): Observable<any> {
    const url = environment.RYEC_API_URL + "templateDetails";

    return this.hC
      .post<any>(url, postJson, this.HttpHeaderOptions)
      .pipe(map((res: any) => {
        return res;
      }));
  }

  getSubAssetByAsset(postJson?: {}): Observable<any> {
    const url = environment.RYEC_API_URL + "getSubAssetByAsset";

    return this.hC
      .post<any>(url, postJson, this.HttpHeaderOptions)
      .pipe(map((res: any) => {
        return res;
      }));
  }

  /**
   * get report business reason list by id
   */
  getSubCategoryListByParentCategoryId(postJson?: {}): Observable<any> {
    const url = environment.RYEC_API_URL + "getSubCategoryByCategoryId";

    return this.hC
      .post<any>(url, postJson, this.HttpHeaderOptions)
      .pipe(map((res: any) => {
        return res;
      }));
  }

  /**
   * Get latLong from network
   */
  getNetworkLatLong() {
    this.hC
      .get("https://ipinfo.io/json?token=1f8b9df2e5cc81")
      .subscribe((data:any) => {
        const latlog = data["loc"].split(",");
        if (latlog.length > 1) {
          const lat = latlog[0];
          const log = latlog[1];
          localStorage.setItem("latitude", lat);
          localStorage.setItem("longitude", log);
          this.cS.updateLatLong(lat, log);
        }
        return;
      });
  }

  /**
   * Get Search business listing
   * @param postJson
   */
  getSearchBusinesses(postJson?: {}): Observable<any> {
    const url = environment.RYEC_API_URL + "getSearchBusinesses";

    return this.hC
      .post<any>(url, postJson, this.HttpHeaderOptions)
      .pipe(map((res: any) => {
        return res;
      }));
  }

  getBusinessApproved(postJson?: {}): Observable<any> {
    const url = environment.RYEC_API_URL + "getBusinessApproved";

    return this.hC
      .post<any>(url, postJson, this.HttpHeaderOptions)
      .pipe(map((res: any) => {
        return res;
      }));
  }

  /**
   * Get Premium Business listing
   * @returns Observable<BusinessRes>
   */
  getPremiumBusinessListing(
    postJson?: any
  ): Observable<BusinessRes> {
    let body = "";

    if (postJson) {
      for (const key in postJson) {
        if (postJson.hasOwnProperty(key)) {
          body += key + "=" + postJson[key] + "&";
        }
      }
    }
    const url = environment.RYEC_API_URL + "getPremiumBusinesses?" + body;
    console.log(url,"hiiiiiiiiiiii")

    return this.hC.get<BusinessRes>(url).pipe(map((res: BusinessRes) => {
      if (postJson) {
        if ("limit" in postJson) {
          this.promotedBusinessList = res;
        }
      }
      return res;
    }));
  }

  getNotificationList(postJson?: any): Observable<any> {
    const url = environment.RYEC_API_URL + "notificationList";

    return this.hC
      .post<any>(url, postJson, this.HttpHeaderOptions)
      .pipe(map((res: any) => {
        return res;
      }));
  }

  /**
   * Get Market Place listing data
   * @returns Observable<MarketPlaceListRes>
   */
  getMarketPlaceList(
    postJson?: PostJsonMarketPlaceList,
    type?: any
  ): Observable<MarketPlaceListRes> {
    const url = environment.RYEC_API_URL + type;

    return this.hC
      .post<MarketPlaceListRes>(url, postJson, this.HttpHeaderOptions)
      .pipe(map((res: MarketPlaceListRes) => {
        return res;
      }));
  }

  getMarketPlaceDetail(postJson?: {}, type?: any): Observable<MarketPlaceDetailRes> {
    const url = environment.RYEC_API_URL + type;

    return this.hC
      .post<MarketPlaceDetailRes>(url, postJson, this.HttpHeaderOptions)
      .pipe(map((res: MarketPlaceDetailRes) => {
        return res;
      }));
  }

  getEventCategoryList(): Observable<any> {
    const url = environment.RYEC_API_URL + "eventCategory";
    return this.hC.post<any>(url, this.HttpHeaderOptions).pipe(map((res: any) => {
      return res;
    }));
  }
}
