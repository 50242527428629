/// <reference types="@angular/localize" />

import { platformBrowserDynamic } from "@angular/platform-browser-dynamic";

import { AppModule } from "./app/app.module";
import { environment } from "./environments/environment";

if (window.location.href.indexOf("localhost:4200") > 0) {
  environment.APPLICATION_ID = "4";
  environment.applicationId = 4;
  environment.API_SECURITY_KEY =
    "basic NDpFQVNZTEFXT1JERVI6RUwtMjAyNDpFTDpFTkQtVVNFUg==";
}
if (window.location.href.indexOf("localhost:4100") > 0) {
  environment.APPLICATION_ID = "1";
  environment.applicationId = 1;
  environment.API_SECURITY_KEY =
    "basic MTpNWVJBSkFTVEhBTkNMVUI6TVJDLTIwMjI6TVJDOkVORC1VU0VS";
}

if (window.location.href.indexOf("easylaworder") > 0) {
  environment.APPLICATION_ID = "4";
  environment.applicationId = 4;
  environment.API_SECURITY_KEY =
    "basic NDpFQVNZTEFXT1JERVI6RUwtMjAyNDpFTDpFTkQtVVNFUg==";
}
if (window.location.href.indexOf("rajasthancircle") > 0) {
  environment.APPLICATION_ID = "1";
  environment.applicationId = 1;
  environment.API_SECURITY_KEY =
    "basic MTpNWVJBSkFTVEhBTkNMVUI6TVJDLTIwMjI6TVJDOkVORC1VU0VS";
}

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch((err) => console.error(err));
